const config = {
    maps: {
        dust2: {
            title: 'Dust2',
            chTitle: '炙热沙城Ⅱ',
            imgSrc: require('../assets/cs2/map/dust2.jpeg'),
            radarImgSrc: require('../assets/cs2/map/dust2Radar.webp'),
        },
        inferno: {
            title: 'Inferno',
            chTitle: '炼狱小镇',
            imgSrc: require('../assets/cs2/map/inferno.jpeg'),
            radarImgSrc: require('../assets/cs2/map/infernoRadar.webp'),
        },
        mirage: {
            title: 'Mirage',
            chTitle: '荒漠迷城',
            imgSrc: require('../assets/cs2/map/mirage.jpeg'),
            radarImgSrc: require('../assets/cs2/map/mirageRadar.webp'),
        },
        ancient: {
            title: 'Ancient',
            chTitle: '远古遗迹',
            imgSrc: require('../assets/cs2/map/ancient.jpeg'),
            radarImgSrc: require('../assets/cs2/map/ancientRadar.webp'),
        },
        anubis: {
            title: 'Anubis',
            chTitle: '阿努比斯',
            imgSrc: require('../assets/cs2/map/anubis.jpeg'),
            radarImgSrc: require('../assets/cs2/map/anubisRadar.webp'),
        },
        nuke: {
            title: 'Nuke',
            chTitle: '核子危机',
            imgSrc: require('../assets/cs2/map/nuke.jpeg'),
            radarImgSrc: require('../assets/cs2/map/nukeRadar.png'),
        },
        vertigo: {
            title: 'Vertigo',
            chTitle: '殒命大厦',
            imgSrc: require('../assets/cs2/map/vertigo.jpeg'),
            radarImgSrc: require('../assets/cs2/map/vertigoRadar.webp'),
        }
    },
    side: {
        1: {
            title: 'T',
            imgSrc: require('../assets/cs2/side/side_t.png'),
        },
        2: {
            title: 'CT',
            imgSrc: require('../assets/cs2/side/side_ct.png'),
        },
        3: {
            title: 'Both',
            imgSrc: require('../assets/cs2/side/side_both.png'),
        }
    },
    ability: {
        1: {
            imgSrc: require('../assets/cs2/ability/Smoke.png')
        },
        2: {
            imgSrc: require('../assets/cs2/ability/Molly.png')
        },
        3: {
            imgSrc: require('../assets/cs2/ability/Hegrenade.png')
        },
        4: {
            imgSrc: require('../assets/cs2/ability/Flashbang.png')
        }
    },
    agent: {
        imgSrc: require('../assets/cs2/startPoint.png')
    },
    leaflet: {
        addLineup: {
            point: {
                radius: 10,
                startPointFillColor: '#00FF00',
                endPointFillColor: '#FFFF00',
            },
        },
        showLineup: {
            agentImgSize: 70,
            togetherDistance: 14,
            togetherLineup: {
                countCircle: {
                    radius: 18,
                    circleColor: '#FFFFFF',
                },
            },
            abilityImgSize: 60,
            endCircleMouseOver: {
                radius: 40,
                color: '#ffffff',
                weight: 2,
            },
            endCircle: {
                radius: 35,
                fillColor: '#2a2c31',
                fillOpacity: 1,
                weight: 0,
            },
            agentCircle: {
                radius: 30,
                weight: 2,
                fillColor: '#ff0000',
                fillOpacity: 0.3,
                color: '#ff0000',
            },
            startEndLine: {
                color: '#ffffff',
                weight: 2,
            }
        }
    }
}
export {config}