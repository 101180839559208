<template>
  <div class="main">
    <!--电脑端页面-->
    <template v-if="!isPhoneWidth">
      <!--左边选择框-->
      <div class="left-bar">
        <!--地图选项-->
        <el-card class="box-card" :body-style="{ padding: '5px' }">
          <div class="map-agent">
            <!--选择地图-->
            <div class="select-map" @click="showMapOptions">
              <img :src="config.maps[this.selectMap].imgSrc" alt="">

              <div class="select-map-title">
                <div>
                  {{ config.maps[this.selectMap].title }}
                </div>
                <div>
                  {{ config.maps[this.selectMap].chTitle }}
                </div>
              </div>
            </div>
            <!--选择ct/t -->
            <div class="select-agent">
              <img :src="config.side[this.selectSide].imgSrc" alt="" @click="changeSideOptions">
            </div>
          </div>

          <!--道具选项-->
          <el-divider content-position="left">道具</el-divider>
          <div class="ability-options">
            <template v-for="(value, name, index) in config.ability">
              <div :key="index" class="ability-item" @click="clickAbilityOption(name)">
                <img :src="value.imgSrc" v-bind:class="{'img-opacity2': selectAbility!=name}">
              </div>
            </template>
          </div>
        </el-card>

        <!--添加lineup-->
        <el-card class="box-card" style="margin-top: 20px" v-if="$store.state.userinfo.isAdmin">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">添加LINEUP</span>
          </div>
          <el-row>
            <el-col :span="4">ID</el-col>
            <el-col :span="18">
              <el-input size="mini" v-model="lineupForm.blogId"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">标题</el-col>
            <el-col :span="18">
              <el-input size="mini" v-model="lineupForm.title"></el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">站点</el-col>
            <el-col :span="10">
              <el-input :disabled="true" size="mini" v-model="lineupForm.startPoint"></el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" size="mini" @click="selectPoint('start')">地图选点</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="4">落点</el-col>
            <el-col :span="10">
              <el-input :disabled="true" size="mini" v-model="lineupForm.endPoint"></el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" size="mini" @click="selectPoint('end')">地图选点</el-button>
            </el-col>
          </el-row>

          <!--提交-->
          <el-row style="margin-top: 10px">
            <el-button type="primary" size="mini" @click="submitLineup">提交</el-button>
          </el-row>
        </el-card>


        <!--ID速查-->
        <el-card class="box-card" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">ID速查</span>
          </div>
          <el-row>
            <el-col :span="4" style="margin-left: 10px;font-size: 18px">ID</el-col>
            <el-col :span="10">
              <el-input size="mini" v-model="inputID" @keyup.enter.native="submitInputId"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" size="mini" @click="submitInputId">确定</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <!--中间地图主体-->
      <div class="mid-bar">
        <div id="map" ref="map" @mousemove="move">

        </div>
      </div>
      <!--右边-->
      <div class="right-bar">
        <!--网站公告-->
        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-bottom: 20px"
                 v-if="$store.state.userinfo.isAdmin || showCs2Announcement">
          <div slot="header" class="clearfix">
            <span style="font-weight: 600">公告</span>
            <el-switch
                v-if="$store.state.userinfo.isAdmin"
                v-model="showCs2Announcement"
                @change="changeShowCs2Announcement"
                active-color="#13ce66"
                inactive-color="#dddfe6"
                style="float: right; padding: 3px 0"
            >
            </el-switch>
          </div>
          <template v-if="isEditingAnnouncement">
            <el-input
                @change="submitAnnouncement"
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="cs2Announcement">
            </el-input>
          </template>
          <template v-else>
            <div style="white-space: pre-line; margin-left: 10px; margin-right: 10px; min-height: 12px"
                 @click="editAnnouncement">
              {{ cs2Announcement }}
            </div>
          </template>
        </el-card>
      </div>
    </template>
    <!--手机端页面-->
    <template v-else>
      <!--中间地图主体-->
      <div class="mid-bar-mobile" style="margin: 3px; padding: 0">
        <el-card class="box-card" :body-style="{ padding: '5px'}">

          <div class="map-agent" style="justify-content: start;">
            <div class="select-map" @click="showMapOptions"
                 style="margin-left: 10px;margin-right: 10px; width: 150px; height: 50px">
              <img :src="config.maps[this.selectMap].imgSrc" alt="">

              <div class="select-map-title">
                <div>
                  {{ config.maps[this.selectMap].chTitle }}
                </div>
              </div>
            </div>
            <div class="select-agent" style="width: 50px">
              <img :src="config.side[this.selectSide].imgSrc" alt="" @click="changeSideOptions">
            </div>
            <a href="#" @click="clickGameOption('val')" style="margin-left: 10px">切换至无畏契约</a>
          </div>
          <!--道具选项-->
          <div class="ability-options" style="margin-top: 10px">
            <template v-for="(value, name, index) in config.ability">
              <div class="ability-item" @click="clickAbilityOption(name)" :key="index"
                   style="width: 40px; height: 40px">
                <img :src="value.imgSrc" alt="" v-bind:class="{'img-opacity2': selectAbility!=name}"
                     style="width: 25px; height: 25px">
              </div>
            </template>
          </div>
        </el-card>
        <!--        <div id="map" ref="map" @mousemove="move" style="height: calc(100vh - 230px)">-->

        <!--        </div>-->
        <div id="map" ref="map" @mousemove="move" :style="'height:' + phoneMapHeight">

        </div>
        <el-card class="box-card" :body-style="{ padding: '5px'}" v-show="phoneDetailVisible">
          <div class="images" v-if="imageList.length !== 0">
            <template v-for="(item, index) in imageList">
              <!--              <el-image-->
              <!--                  style="margin-top: 10px"-->
              <!--                  :src="item"-->
              <!--                  :preview-src-list="imageList"-->
              <!--                  :key="index"-->
              <!--              >-->
              <!--              </el-image>-->
              <img
                  :src="item"
                  :key="index" width="100%">
            </template>

          </div>
          <div v-if="videoSrc" style="margin-top: 20px;font-size: 16px">
            <a :href="linkWithSecond" target="_blank">点击跳至B站查看原视频</a>
            <template v-if="discussData.bili_start_minute===0 && discussData.bili_start_second===0">
            </template>
            <template v-else>
              (精准空降{{ discussData.bili_start_minute }}分{{ discussData.bili_start_second }}秒)
            </template>
            <div class="biliVideo">
              <iframe :src="this.videoSrc" allowfullscreen="allowfullscreen"></iframe>
            </div>
          </div>
        </el-card>
      </div>
    </template>

    <!--地图选项-->
    <el-dialog
        title="选择地图"
        :visible.sync="mapOptionsVisible"
        :width="dialogWidth">
      <div class="map-options">
        <template v-for="(value, name, index) in config.maps">
          <div class="map-item" :key="index" @click="clickMapOption(name)">
            <img :src="value.imgSrc" alt="">
            <div class="map-title">
              <div>{{ value.title }}</div>
              <div>{{ value.chTitle }}</div>
            </div>
          </div>
        </template>
      </div>

    </el-dialog>


    <!--悬浮框-->
    <div v-show="leafletTooltipVisible && !isPhoneWidth" class="hover_con" :style="positionStyle">
      {{ leafletTooltipContent }}
    </div>
  </div>
</template>

<script>
import {config} from "@/common/cs2config";
import L from 'leaflet'
import axios from 'axios'
import {distance, imgNameToUrl} from "@/common/comm";
import store from "@/store";
import {biliLinkToIframe} from "@/common/bili";

function svgText(txt) {
  return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30"><text class="testscg" x="0" y="30" style="font-size: 20px;">' + txt + '</text></svg>';
}

const PHONE_WIDTH = 768
let map
let startPoint //添加lineup时的站点
let endPointGroup //添加lineup时的落点
let endCircleLayerGroup
let mouseOverLayerGroup
let multipleLayerGroup
export default {
  name: "Cs2",

  data() {
    return {
      config,
      source: null, // 存放取消的请求方法
      isPhoneWidth: false,

      selectMap: localStorage.getItem('cs2SelectMap') ? localStorage.getItem('cs2SelectMap') : 'dust2',
      selectSide: localStorage.getItem('cs2SelectSide') ? localStorage.getItem('cs2SelectSide') : 3,
      selectAbility: localStorage.getItem('cs2SelectAbility') ? localStorage.getItem('cs2SelectAbility') : 1,

      mapOptionsVisible: false,
      lineupForm: {
        blogId: null,
        title: '',
        startPoint: null,
        endPoint: null,
        //添加一个范围 sova的箭，fade的眼
        isTempPolygon: false,
        tempPolygonList: [],
        polygonList: [],
        // 添加一条折线，此折线会直接显示并且以endPoint为起点， cypher的拌线，viper的墙
        isTempPolylineWithEndPoint: false,
        polylineWithEndPointList: [],
        //额外的折线组 viper烟墙可能穿过传送门，比较特殊，暂时不能从地图直接添加，可在数据库修改数据
        isTempExtraPolylineList: false,
        tempExtraPolylineList: [],
        extraPolylineList: [],
      },
      // 鼠标移入时显示的lineup名称悬浮框
      positionStyle: {top: '0px', left: '0px'},
      leafletTooltipVisible: false,
      leafletTooltipContent: '',
      // 正在进行地图选点
      isSelectPointing: false,
      // 手机端显示图片和视频
      phoneDetailVisible: false,
      imageList: [],
      videoSrc: '',
      linkWithSecond: '',
      discussData: {},
      // id速查：
      inputID: '',
      //公告
      showCs2Announcement: false,
      cs2Announcement: '',
      isEditingAnnouncement: false,
      // 手机端的地图高度
      phoneMapHeight: '60vh',
    }
  },
  computed: {
    dialogWidth() {
      return this.isPhoneWidth ? '70%' : '30%'
    }
  },
  created() {
    this.handleWindowWidth()
    // window.addEventListener('resize', this.handleWindowWidth)
    window.addEventListener('resize', this.handlePhoneMapWidth)
  },
  mounted() {
    store.commit('setCurrentPage', 'cs2')
    localStorage.setItem('currentPage', 'cs2')
    this.initMap(this.selectMap)
    this.getConfig()
    this.selectChange()
    this.handleSetLineup() // 添加新的lineup时处理url参数
  },
  methods: {
    handleWindowWidth() {
      this.isPhoneWidth = window.innerWidth <= PHONE_WIDTH;
    },
    handlePhoneMapWidth() {
      if (window.innerWidth < window.innerHeight) {
        this.phoneMapHeight = '60vh'
      } else {
        this.phoneMapHeight = '100vh'
      }
    },
    initMap(selectMap) {
      var _this = this
      if (map) {
        map.remove()
      }
      const pngHeight = 2000
      const mapDivHeight = this.$refs.map.offsetHeight
      const mapDivWidth = this.$refs.map.offsetWidth
      let mapZoom
      if (mapDivHeight > mapDivWidth) {
        mapZoom = Math.log2(mapDivWidth / pngHeight)
      } else {
        mapZoom = Math.log2(mapDivHeight * 0.95 / pngHeight)
      }
      map = L.map('map', {
        crs: L.CRS.Simple,
        zoom: mapZoom,
        zoomSnap: 0.1,
        attributionControl: false,
      });
      var bounds = [[0, 0], [2000, 2000]]
      L.imageOverlay(config.maps[selectMap].radarImgSrc, bounds).addTo(map)
      map.fitBounds(bounds);
      map.setView([1000, 1000], mapZoom)
    },
    move(event) {
      const x = event.pageX + 15 + 'px'
      const y = event.pageY + 10 + 'px'
      this.positionStyle = {top: y, left: x}
    },
    showMapOptions() {
      this.mapOptionsVisible = true
    },
    clickMapOption(name) {
      this.selectMap = name
      localStorage.setItem('cs2SelectMap', name)
      this.hideMapOptions()
      this.initMap(this.selectMap)
      this.selectChange()
    },
    hideMapOptions() {
      this.mapOptionsVisible = false
    },
    selectChange() {
      const _this = this;
      this.cancelRequest();
      axios({
        method: 'get',
        url: '/api/blogs/get_cs2_lineup_list',
        params: {
          map_name: this.selectMap,
          ability: this.selectAbility,
          side: this.selectSide,
        },
        cancelToken: new axios.CancelToken(function executor(c) {
          _this.source = c
        })
      }).then(r => {
        if (r.data.code === 0) {
          this.initLineup(r.data.list)
        } else {
          this.$message.error('加载失败');
        }
      })
    },
    initLineup(dataArr) {
      let _this = this
      if (endCircleLayerGroup) {
        endCircleLayerGroup.remove()
      }
      if (multipleLayerGroup) {
        multipleLayerGroup.remove()
      }
      // 对相同落点的lineup进行合并处理
      let togetherList = []
      for (let i = 0; i < dataArr.length; i++) {
        if (!dataArr[i]) {
          continue
        }

        let lineupObj = dataArr[i]
        let endX = lineupObj.leaflet_params.endPoint[0]
        let endY = lineupObj.leaflet_params.endPoint[1]
        let togetherObj = {
          count: 1,
          endX: endX,
          endY: endY,
          list: [lineupObj],
          agent: lineupObj.agent_name,
          ability: lineupObj.ability,
        }
        for (let j = i + 1; j < dataArr.length; j++) {
          if (!dataArr[j]) {
            continue
          }
          let tmpObj = dataArr[j]
          let tmpEndX = tmpObj.leaflet_params.endPoint[0]
          let tmpEndY = tmpObj.leaflet_params.endPoint[1]
          if (distance(dataArr[i], dataArr[j]) < config.leaflet.showLineup.togetherDistance) {
            togetherObj.endX = (togetherObj.endX * togetherObj.count + tmpEndX) / (togetherObj.count + 1)
            togetherObj.endY = (togetherObj.endY * togetherObj.count + tmpEndY) / (togetherObj.count + 1)
            togetherObj.count += 1
            togetherObj.list.push(dataArr[j])
            dataArr[j] = null
          }
        }
        togetherList.push(togetherObj)
      }
      // 显示所有lineup
      let arr = []
      for (let i = 0; i < togetherList.length; i++) {
        let togetherObj = togetherList[i]
        // 技能图片
        const endX = togetherObj.endX
        const endY = togetherObj.endY
        let imgSize = config.leaflet.showLineup.abilityImgSize
        let agent = togetherObj.agent
        let ability = togetherObj.ability
        let pngBounds = [[endX - imgSize / 2, endY - imgSize / 2], [endX + imgSize / 2, endY + imgSize / 2]]
        let png = L.imageOverlay(config.ability[ability].imgSrc, pngBounds, {
          zIndex: 401,
          interactive: true,
          lineupList: togetherObj.list,
          togetherObj: togetherObj
        })
        png.on('mouseover', endCircleMouseOver)
        png.on('mouseout', endCircleMouseOut)
        png.on('click', endCircleClick)
        arr.push(png)
        // 技能透明圆圈
        let endCircle = L.circle([endX, endY], {
          radius: config.leaflet.showLineup.endCircle.radius,
          fillColor: config.leaflet.showLineup.endCircle.fillColor,
          fillOpacity: config.leaflet.showLineup.endCircle.fillOpacity,
          weight: config.leaflet.showLineup.endCircle.weight,
          lineupList: togetherObj.list,
          togetherObj: togetherObj
        })
        endCircle.on('mouseover', endCircleMouseOver)
        endCircle.on('mouseout', endCircleMouseOut)
        endCircle.on('click', endCircleClick)
        arr.push(endCircle)
        // 如果合并过的点位，显示具体数字
        // if (togetherObj.count > 1) {
        //   // 显示圆圈
        //   let endCircleRadius = config.leaflet.showLineup.endCircle.radius
        //   let togetherCountCircle = L.circle([endX + endCircleRadius, endY + endCircleRadius], {
        //     radius: config.leaflet.showLineup.togetherLineup.countCircle.radius,
        //     fillColor: config.leaflet.showLineup.togetherLineup.countCircle.circleColor,
        //     fillOpacity: 1,
        //     weight: 0,
        //   })
        //   arr.push(togetherCountCircle)
        //   //显示数字
        //   let img = 'data:image/svg+xml,' + encodeURIComponent(svgText(togetherObj.count));
        //   let imgSize = 50
        //   let countOffset = 14
        //   let pngBounds = [[endX - imgSize / 2 + endCircleRadius + countOffset, endY - imgSize / 2 + endCircleRadius + countOffset], [endX + imgSize / 2 + endCircleRadius + countOffset, endY + imgSize / 2 + endCircleRadius + countOffset]]
        //   let countPng = L.imageOverlay(img, pngBounds, {
        //     zIndex: 401,
        //     interactive: true,
        //   })
        //   arr.push(countPng)
        // }
      }
      endCircleLayerGroup = L.layerGroup(arr)
      endCircleLayerGroup.addTo(map)

      // 鼠标移入技能图片事件
      function endCircleMouseOver(e) {
        //加入到mouseover组显示
        let arr = []
        //显示一个小白圈
        let endX = e.sourceTarget.options.togetherObj.endX
        let endY = e.sourceTarget.options.togetherObj.endY
        let circle = L.circle([endX, endY], {
          radius: config.leaflet.showLineup.endCircleMouseOver.radius,
          color: config.leaflet.showLineup.endCircleMouseOver.color,
          weight: config.leaflet.showLineup.endCircleMouseOver.weight,
        })

        let lineupList = e.sourceTarget.options.lineupList
        //悬浮框
        if (lineupList.length === 1) {
          _this.leafletTooltipVisible = true
          _this.leafletTooltipContent = lineupList[0].lineup_title
        }

        for (let i = 0; i < lineupList.length; i++) {
          let lineupObj = lineupList[i]
          arr.push(circle)

          if (lineupObj.leaflet_params.startPoint) {
            let startX = lineupObj.leaflet_params.startPoint[0]
            let startY = lineupObj.leaflet_params.startPoint[1]
            //显示起始点的一条直线
            let startEndLine = L.polyline([[startX, startY], [endX, endY]], {
              color: config.leaflet.showLineup.startEndLine.color,
              weight: config.leaflet.showLineup.startEndLine.weight,
            });
            arr.push(startEndLine)
            //显示站点
            // let agentCircle = L.circle([startX, startY], {
            //   radius: config.leaflet.showLineup.agentCircle.radius,
            //   weight: config.leaflet.showLineup.agentCircle.weight,
            //   fillOpacity: config.leaflet.showLineup.agentCircle.fillOpacity,
            //   fillColor: config.leaflet.showLineup.agentCircle.fillColor,
            //   color: config.leaflet.showLineup.agentCircle.color,
            // })
            // arr.push(agentCircle)
            // 显示站点图片
            let imgSize = config.leaflet.showLineup.agentImgSize
            let pngBounds = [[startX - imgSize / 2, startY - imgSize / 2], [startX + imgSize / 2, startY + imgSize / 2]]
            let agentPng = L.imageOverlay(config.agent.imgSrc, pngBounds, {
              zIndex: 401,
              interactive: true,
            })
            arr.push(agentPng)
          }
        }
        //mouseover组显示
        mouseOverLayerGroup = L.layerGroup(arr)
        mouseOverLayerGroup.addTo(map)
      }

      // 鼠标移出技能图片事件
      function endCircleMouseOut() {
        //悬浮框
        _this.leafletTooltipVisible = false
        if (mouseOverLayerGroup) {
          mouseOverLayerGroup.remove()
        }
      }

      //鼠标点击技能图片事件
      function endCircleClick(e) {
        _this.leafletTooltipVisible = false
        if (_this.isSelectPointing) {
          //如果正在地图选点，不触发点击事件
          return
        }
        if (multipleLayerGroup) {
          multipleLayerGroup.remove()
        }

        let lineupList = e.sourceTarget.options.lineupList
        if (lineupList.length === 1) {
          let lineupObj = lineupList[0]
          if (_this.isPhoneWidth) { // 手机端 直接显示在下方
            _this.handlePhoneCircle(lineupObj.blog_id)
          } else { //PC端 打开一个新页面
            window.open('/discuss/' + lineupObj.blog_id)
          }
        }

        let arr = []
        if (lineupList.length > 1) {
          _this.$message('多个站位，点击站位查看对应教学');
          //显示一个小白圈
          let endX = e.sourceTarget.options.togetherObj.endX
          let endY = e.sourceTarget.options.togetherObj.endY
          let circle = L.circle([endX, endY], {
            radius: config.leaflet.showLineup.endCircleMouseOver.radius,
            color: config.leaflet.showLineup.endCircleMouseOver.color,
            weight: config.leaflet.showLineup.endCircleMouseOver.weight,
          })
          arr.push(circle)

          for (let i = 0; i < lineupList.length; i++) {
            let lineupObj = lineupList[i]
            let startX = lineupObj.leaflet_params.startPoint[0]
            let startY = lineupObj.leaflet_params.startPoint[1]
            //显示起始点的一条直线
            let startEndLine = L.polyline([[startX, startY], [endX, endY]], {
              color: config.leaflet.showLineup.startEndLine.color,
              weight: config.leaflet.showLineup.startEndLine.weight,
            });
            arr.push(startEndLine)
            //显示站点
            // let agentCircle = L.circle([startX, startY], {
            //   radius: config.leaflet.showLineup.agentCircle.radius,
            //   weight: config.leaflet.showLineup.agentCircle.weight,
            //   fillOpacity: config.leaflet.showLineup.agentCircle.fillOpacity,
            //   fillColor: config.leaflet.showLineup.agentCircle.fillColor,
            //   color: config.leaflet.showLineup.agentCircle.color,
            //   lineupList: [lineupObj]
            // })
            // agentCircle.on('mouseover', agentCircleMouseOver)
            // agentCircle.on('mouseout', agentCircleMouseOut)
            // agentCircle.on('click', endCircleClick)
            // arr.push(agentCircle)
            // 显示站点图片
            let imgSize = config.leaflet.showLineup.agentImgSize
            let pngBounds = [[startX - imgSize / 2, startY - imgSize / 2], [startX + imgSize / 2, startY + imgSize / 2]]
            let agentPng = L.imageOverlay(config.agent.imgSrc, pngBounds, {
              zIndex: 401,
              interactive: true,
              lineupList: [lineupObj]
            })
            agentPng.on('mouseover', agentCircleMouseOver)
            agentPng.on('mouseout', agentCircleMouseOut)
            agentPng.on('click', endCircleClick)
            arr.push(agentPng)
          }
        }
        multipleLayerGroup = L.layerGroup(arr)
        multipleLayerGroup.addTo(map)
      }

      function agentCircleMouseOver(e) {
        // 相同落点的道具点击之后，鼠标移入站点时悬浮框出现
        let lineupList = e.sourceTarget.options.lineupList
        if (lineupList.length === 1) {
          _this.leafletTooltipVisible = true
          _this.leafletTooltipContent = lineupList[0].lineup_title
        }
      }

      function agentCircleMouseOut() {
        _this.leafletTooltipVisible = false
      }
    },
    cancelRequest() {
      if (typeof this.source === 'function') {
        this.source('终止请求')
      }
    },
    handleSetLineup() {
      if (this.$route.query.blogid) {
        this.lineupForm.blogId = this.$route.query.blogid
      }
    },
    clickAbilityOption(name) {
      this.selectAbility = name
      localStorage.setItem('cs2SelectAbility', name)
      this.selectChange()
    },
    selectPoint(type) {
      if (type === 'start') {
        this.$message({
          message: '点击地图位置，选择人物站点',
          duration: 2000,
        })
      } else {
        this.$message({
          message: '点击地图位置，选择道具落点',
          duration: 2000,
        })
      }
      let _this = this
      this.isSelectPointing = true
      map.on('click', function (e) {
        alert(e.latlng)
        const x = Math.round(e.latlng.lat)
        const y = Math.round(e.latlng.lng)
        if (type === 'start') { // 选择站点
          if (startPoint) {
            startPoint.remove()
          }
          startPoint = L.circle([x, y], {
            radius: config.leaflet.addLineup.point.radius,
            fillColor: config.leaflet.addLineup.point.startPointFillColor,
            weight: 0,
            fillOpacity: 1,
          }).addTo(map);
          _this.lineupForm.startPoint = [x, y]
        } else { //选择落点
          if (endPointGroup) {
            endPointGroup.remove()
          }
          let arr = []
          let endPoint = L.circle([x, y], {
            radius: config.leaflet.addLineup.point.radius,
            fillColor: config.leaflet.addLineup.point.endPointFillColor,
            weight: 0,
            fillOpacity: 1,
          })
          _this.lineupForm.endPoint = [x, y]
          arr.push(endPoint)
          // 技能图片
          let imgSize = config.leaflet.showLineup.abilityImgSize
          let pngBounds = [[x - imgSize / 2, y - imgSize / 2], [x + imgSize / 2, y + imgSize / 2]]
          let png = L.imageOverlay(config.ability[_this.selectAbility].imgSrc, pngBounds, {
            zIndex: 401,
            interactive: true,
          })
          arr.push(png)
          // 技能透明圆圈
          let endCircle = L.circle([x, y], {
            radius: config.leaflet.showLineup.endCircle.radius,
            fillColor: config.leaflet.showLineup.endCircle.fillColor,
            fillOpacity: config.leaflet.showLineup.endCircle.fillOpacity,
            weight: config.leaflet.showLineup.endCircle.weight,
          })
          arr.push(endCircle)
          endPointGroup = L.layerGroup(arr)
          endPointGroup.addTo(map)
        }

        map.off('click')
        this.isSelectPointing = false
      });
    },
    submitLineup() {
      axios({
        method: 'post',
        url: '/api/blogs/submit_cs2_lineup',
        data: Object.assign({}, this.lineupForm, {
          map_name: this.selectMap,
          ability: this.selectAbility,
          side: this.selectSide
        })
      }).then(r => {
        if (r.data.code === 0) {
          this.lineupForm = {
            blogId: null,
            title: '',
            startPoint: null,
            endPoint: null,

            isTempPolygon: false,
            tempPolygonList: [],
            polygonList: [],

            isTempPolylineWithEndPoint: false,
            polylineWithEndPointList: [],
            extraPolylineList: [],
          }
          this.$router.push({query: {}});
          this.selectChange()
          this.isSelectPointing = false
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        } else {
          this.$message.error(r.data.msg);
        }
      })
    },
    changeSideOptions() {
      this.selectSide = this.selectSide % 3 + 1
      localStorage.setItem('cs2SelectSide', this.selectSide)
      this.selectChange()
    },
    handlePhoneCircle(blog_id) {
      this.phoneDetailVisible = true
      axios({
        method: 'get',
        url: '/api/blogs/get_discuss_detail',
        params: {
          blog_id: blog_id,
        }
      }).then(r => {
        this.discussData = r.data.detail
        this.imageList = []
        // 处理图片
        for (let i = 0; i < this.discussData.images.length; i++) {
          //this.imageList.push(`https://lkval-1259337012.cos.ap-nanjing.myqcloud.com/lkval/main/${this.discussData.images[i].split('.')[0] + '.' + 'webp'}`)
          this.imageList.push(imgNameToUrl(this.discussData.images[i]))
        }
        // 处理视频
        if (this.discussData.bili_link) {
          const [videoSrc, linkWithSecond] = biliLinkToIframe(this.discussData.bili_link, this.discussData.bili_start_minute, this.discussData.bili_start_second, this.discussData.bili_iframe)
          this.videoSrc = videoSrc
          this.linkWithSecond = linkWithSecond
        }
        this.$message({
          message: '加载成功',
          type: 'success',
          duration: 500,
        });
      })
    },
    submitInputId() {
      let inputID = this.inputID.trim()
      let reg = /^[0-9]+$/;
      if (reg.test(inputID)) {
        window.open('/discuss/' + inputID)
      } else {
        this.$message.error('ID为数字');
      }
    },
    editAnnouncement() {
      if (this.$store.state.userinfo.isAdmin) {
        this.isEditingAnnouncement = true
      }
    },
    submitAnnouncement() {
      axios({
        method: 'post',
        url: '/api/blogs/edit_config',
        data: {
          name: 'cs2Announcement',
          value: this.cs2Announcement,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
      this.isEditingAnnouncement = false
    },
    changeShowCs2Announcement(value) {
      axios({
        method: 'post',
        url: '/api/blogs/edit_config',
        data: {
          name: 'showCs2Announcement',
          value: value.toString()
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
    },
    getConfig() {
      axios({
        method: 'get',
        url: '/api/blogs/get_all_config',
      }).then(r => {
        if (r.data.code === 0) {
          let config = r.data.config
          if (config.cs2Announcement) {
            this.cs2Announcement = config.cs2Announcement
          }
          if (config.showCs2Announcement) {
            this.showCs2Announcement = config.showCs2Announcement === "true"
          }
        }
      })
    },
    clickGameOption(game) {
      if (game === 'cs2') {
        this.$router.push("/cs2");
      }
      if (game === 'val') {
        store.commit('setCurrentPage', 'val')
        localStorage.setItem('currentPage', 'val')
        this.$router.push("/");
      }
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  height: calc(100vh - 52px);
  background: #f1f2f5;
  justify-content: center;

}

.left-bar {
  width: 300px;
  padding: 10px;
}

.mid-bar {
  width: calc(100vh - 52px);
  height: calc(100vh - 52px);
  padding: 10px 0;
}

.mid-bar-mobile {
  width: 100%;
  height: calc(100vh - 52px);
  padding: 10px 0;
}

#map {
  height: 100%;
  background-color: #1f252a;
  /*background-color: #3f474f;*/
  border-radius: 10px;
}

.right-bar {
  width: 300px;
  padding: 10px;
}

/*.left-bar {*/
/*  width: 400px;*/
/*  !*padding: 20px;*!*/
/*  height: calc(100vh - 52px);*/
/*  background: #152036;*/
/*  overflow: auto;*/
/*  position: relative;*/
/*}*/

/*.right-bar {*/
/*  top: 52px;*/
/*  left: 400px;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  position: absolute;*/
/*  width: auto;*/
/*  height: auto;*/
/*}*/

/*#map {*/
/*  height: 100%;*/
/*  background-color: #3f474f;*/
/*}*/

.filter-blur-wrapper {
  height: 100%;
}

.filter-blur {

  filter: blur(5px);
}

.selected-map-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 1000
}

.selected-map {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  /*filter: blur(5px);*/
}

.map-options {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.map-options .map-item {
  width: 200px;
  margin: 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.map-options .map-item img {
  width: 100%;
  transition: transform 0.3s;
}

.map-options .map-item:hover > img {
  transform: scale(1.2);
}

.map-options .map-item .map-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.selected-map img {
  transition: transform 0.3s;
  width: 100%;
}

.selected-map:hover > img {
  transform: scale(1.2);
}

.agent-options {
  margin: 10px;
  display: flex;
  flex-flow: wrap;
  /*justify-content: space-evenly;*/
  align-items: center;
}

.agent-options .agent-item {
  margin: 5px;
  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.agent-badge-item {
  margin: 10px;
}

.agent-options .agent-item img {
  width: 100%;
  transition: transform 0.3s;
}

.agent-options .agent-item:hover > img {
  transform: scale(1.2);
}

.img-opacity5 {
  opacity: 0.5;
}

.img-opacity2 {
  opacity: 0.2;
}

.agent-options .agent-item .agent-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.ability-options {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: -14px;
}

.ability-options .ability-item {
  width: 50px;
  height: 50px;
  background: #0f1923;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 14px;
}

.ability-options .ability-item img {
  width: 35px;
  height: 35px;
}

.map-agent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-agent .select-map {
  width: 190px;
  height: 70px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

}

.map-agent .select-map img {
  transition: transform 0.3s;
  width: 100%;
}

.map-agent .select-map:hover > img {
  transform: scale(1.2);
}


.map-agent .select-map .select-map-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.map-agent .select-agent {
  width: 70px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.map-agent .select-agent .agent-title {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 10px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}


.map-agent .select-agent img {
  width: 100%;
  transition: transform 0.3s;
}

.map-agent .select-agent:hover > img {
  transform: scale(1.2);
}

.hover_con {
  position: fixed;
  max-width: 220px;
  padding: 5px;
  border: 1px solid #666;
  background: #ccc;
  z-index: 999;
}

.biliVideo {
  margin-top: 5px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; /* 高度应该是宽度的56% */
}

.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

</style>